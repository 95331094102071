/* eslint-disable no-unused-vars */
import React from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilBriefcase,
  cilGolf,
  cilObjectGroup,
  cilAddressBook,
  cilBrowser,
  cilBuilding,
  cilSettings,
  cilDescription,
  cilPeople,
  cilMoney,
  cilContact,
  cilHouse,
  cilHeadphones,
  cilUser,
  cilPenNib,
  cilBasket,
  cilPuzzle,
  cilApplications,
  cilSpreadsheet,
  cilFeaturedPlaylist,
  cilGlobeAlt,
  cilStorage,
  cilCouch,
  cilBed,
  cilLan,
  cilVolumeHigh,
  cilFactorySlash,
  cilLibrary,
  cilList,
  cilRoom,
  cilDoubleQuoteSansLeft,
  cilTask,
  cilSitemap,
  cilBrightness,
  cilBowling,
  cilShareBoxed,
  cilWalk,
  cilBrushAlt,
  cilSpeedometer,
  cilCalendarCheck,
  cilFridge,
  cilListRich,
  cilHighligt,
} from '@coreui/icons';
import { ReactComponent as UserPeopleIcon } from '../../assets/brand/userPeople.svg';
import { ReactComponent as Dashboard } from '../../assets/brand/dashboard.svg';
import { ReactComponent as Settings } from '../../assets/brand/settings.svg';
import { ReactComponent as Crm } from '../../assets/brand/crm.svg';
import { ReactComponent as Sales } from '../../assets/brand/sales.svg';
import { ReactComponent as Complains } from '../../assets/brand/complains.svg';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import FenceOutlinedIcon from '@mui/icons-material/FenceOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import { CNavGroup, CNavItem } from '@coreui/react';
import {
  MAINCOMPLAINTS,
  ROLELIST,
  // SUPERVISOR,
  // TECHNICIANTASKTABLE,
  // USER,
  MASTER,
  CONTACTMASTER,
  PROPERTYMASTER,
  COMMUNITYMASTER,
  UNITSUBMASTER,
  TECHNICIANLIST,
  SUPERVISORSLIST,
  CUSTOMERCARE,
  LEADSMASTERSUB,
  SPAREPARTSMASTERSUB,
  PROCUREMENTMASTERSUB,
  // SALESPERSONSLIST,
  WORKORDERREPORTS,
  PAYMENTPLAN,
  SALESPERSONSTABLE,
  DEVELOPERLIST,
  SUPPLIERTABLE,
  SUPPLIERSUBMASTER,
  EMPLOYEELIST,
  ASSETSUBMASTER,
  ASSETCATEGORYLIST,
  ASSETMASTER,
  SUBASSETMASTER,
  EMPLOYEESUBMASTER,
  CONTACTSREPORT,
  DASHBOARD,
  PROPERTY,
  RESOURCECATEGORYLIST,
  LEASE,
  SPAREPARTSMASTERLIST,
  PROCUREMENTMASTERLIST,
  CAMPAIGNMASTERLIST,
  LEASESUBMASTER,
  USERMASTERLIST,
  SALESOFFERLISTING,
  SALESSUBMASTER,
  DEALCANCELLATIONFORM,
  LEASE_OFFER,
  VATREGION,
  VATMASTER,
  DEALCANCELLATIONLIST,
  SALESCONTRACTAGREEMENTLIST,
  // APPROVAL,
  SALESDASHBOARD,
  SALESREPORT,
  AUDITTRAIL,
  USERSUBMASTER,
  JOBPLANNERLIST,
  CONTACTACTIVITYREPORT,
  CONTACTPREFERENCEREPORT,
  PROPERTYTOURREPORT,
  APPROVALWORKFLOWLIST,
  JOBPLANERSUBMASTER,
  QUOTATIONLIST,
  CHECKLISTTASKLIST,
  FACILITYBOOKLIST,
  INCIDENTLIST,
  VISITORLIST,
  APPROVALREQUEST,
  LEADSLISTINGREPORT,
  INVOICELIST,
  CRMDASHBOARD,
  RECEIPTLIST,
  PAYMENTLIST,
  GATEPASSLIST,
  CLEANINGJOBLIST,
  ENERGYMANAGEMENT,
  ENERGYMANAGEMENTLISTING,
  ASSETINSPECTIONFORM,
  ENERGYMANAGEMENTSUBMASTER,
  CHECKLISTASSETITEM,
  CHECKLISTITEM,
  QUESTIONNAIREINSPECTION,
  CONTRACTORASSET,
  METERMASTER,
  METERREADINGLISTING,
  UTILITYSUBMASTER,
  EMAILCAMPAIGNREPORT,
} from 'src/utils/constant';
// const userRoles = localStorage.getItem('role');

const _nav = [
  {
    component: CNavGroup,
    name: 'Facility Management',
    to: '/facility',
    values: [
      'frontDesk',
      'supervisorworkOrder',
      'TechnicianworkOrder',
      'Reports',
      'gatepass',
      'quotation',
      'jobplanners',
      'checklisttasks',
      'facilitiesbooking',
      'incidentreport',
      'visitorentry',
      'cleaningjob',
      'energymanagement',
      'assetinspection',
    ],
    icon: <HandymanOutlinedIcon style={{ marginRight: '10px' }} />,
    // <CIcon icon={cilBrightness} customClassName="nav-icon" />,
    items: [
      {
        component: CNavGroup,
        name: 'Work Order',
        to: '/frontdesk/complaints',
        values: [
          'frontDesk',
          'supervisorworkOrder',
          'TechnicianworkOrder',
          'Reports',
        ],
        icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
        roles: ['Admin', 'superadmin', 'facilitymanager'],
        items: [
          {
            component: CNavItem,
            name: 'Complaints',
            to: MAINCOMPLAINTS,
            values: ['frontDesk', 'supervisorworkOrder', 'TechnicianworkOrder'],
            icon: <Complains className="nav-icon" />,
          },
          {
            component: CNavGroup,
            name: 'Reports',
            to: WORKORDERREPORTS,
            values: ['Reports'],
            icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
            // roles: ['Admin', 'superadmin', 'facilitymanager'],
            items: [
              {
                component: CNavItem,
                name: 'Work Order Report',
                to: WORKORDERREPORTS,
                values: ['Reports'],
                icon: (
                  <CIcon icon={cilDescription} customClassName="nav-icon" />
                ),
              },
            ],
          },
        ],
      },
      {
        component: CNavItem,
        name: 'Gate Pass',
        to: GATEPASSLIST,
        values: ['gatepass'],
        icon: <CIcon icon={cilRoom} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Quotation',
        to: QUOTATIONLIST,
        values: ['quotation'],
        icon: (
          <CIcon icon={cilDoubleQuoteSansLeft} customClassName="nav-icon" />
        ),
      },
      {
        component: CNavItem,
        name: 'Job Planners',
        to: JOBPLANNERLIST,
        values: ['jobplanners'],
        icon: <CIcon icon={cilSitemap} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Checklist Tasks',
        to: CHECKLISTTASKLIST,
        values: ['checklisttasks'],
        icon: <CIcon icon={cilTask} customClassName="nav-icon" />,
        roles: ['Admin', 'superadmin'],
      },
      {
        component: CNavItem,
        name: 'Facilities Booking',
        to: FACILITYBOOKLIST,
        values: ['facilitiesbooking'],
        icon: <CIcon icon={cilBowling} customClassName="nav-icon" />,
        roles: ['Admin', 'superadmin'],
      },
      {
        component: CNavItem,
        name: 'Incident Report',
        to: INCIDENTLIST,
        values: ['incidentreport'],
        icon: <CIcon icon={cilShareBoxed} customClassName="nav-icon" />,
        roles: ['Admin', 'superadmin'],
      },
      {
        component: CNavItem,
        name: 'Visitor Entry',
        to: VISITORLIST,
        values: ['visitorentry'],
        icon: <CIcon icon={cilWalk} customClassName="nav-icon" />,
        roles: ['Admin', 'superadmin'],
      },
      {
        component: CNavItem,
        name: 'Cleaning Job',
        to: CLEANINGJOBLIST,
        values: ['cleaningjob'],
        icon: <CIcon icon={cilBrushAlt} customClassName="nav-icon" />,
        roles: ['Admin', 'superadmin'],
      },
      {
        component: CNavItem,
        name: 'Energy Management',
        to: ENERGYMANAGEMENTLISTING,
        values: ['energymanagement'],
        icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
        roles: ['Admin', 'superadmin'],
      },
      {
        component: CNavItem,
        name: 'Contractor Asset Manintanance',
        to: CONTRACTORASSET,
        values: ['assetinspection'],
        icon: <CIcon icon={cilHighligt} customClassName="nav-icon" />,
        roles: ['Admin', 'superadmin'],
      },
      {
        component: CNavItem,
        name: 'Asset Inspection',
        to: ASSETINSPECTIONFORM,
        values: ['assetinspection'],
        icon: <CIcon icon={cilCalendarCheck} customClassName="nav-icon" />,
        roles: ['Admin', 'superadmin'],
      },
    ],
  },

  {
    component: CNavGroup,
    name: 'Settings',
    to: '/settings',
    values: [
      'settings',
      'property',
      'mainmaster',
      'community',
      'unit',
      'campaign',
      'user',
      'SpareParts',
      'ItemResource',
      'Supplier',
      'ResourceCategory',
      'SubAssetMaster',
      'AssetCategory',
      'VatRegion',
      'Salesperson',
      'PaymentPlan',
      'Developer',
      'employee',
      'AssetMaster',
      'VatMaster',
    ],
    icon: <Settings className="nav-icon" />,
    roles: ['Admin', 'superadmin', 'facilitymanager'],
    items: [
      {
        component: CNavGroup,
        name: 'Main Masters',
        to: '/mainmasters',
        values: [
          'mainmaster',
          'property',
          'community',
          'unit',
          'campaign',
          'user',
          'SpareParts',
          'ItemResource',
          'Supplier',
          'ResourceCategory',
          'SubAssetMaster',
          'AssetCategory',
          'VatRegion',
          'Salesperson',
          'PaymentPlan',
          'Developer',
          'employee',
          'AssetMaster',
          'VatMaster',
        ],
        icon: <Crm className="nav-icon" />,
        roles: ['Admin', 'superadmin', 'facilitymanager'],
        items: [
          {
            component: CNavItem,
            name: 'Property',
            to: PROPERTY,
            values: ['property'],
            icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Community',
            to: '/community',
            values: ['community'],
            icon: <CIcon icon={cilObjectGroup} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Unit',
            to: '/unit',
            values: ['unit'],
            icon: <CIcon icon={cilGolf} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Campaign',
            to: CAMPAIGNMASTERLIST,
            values: ['Campaign'],
            icon: <CIcon icon={cilVolumeHigh} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Spare Parts',
            to: SPAREPARTSMASTERLIST,
            values: ['SpareParts'],
            icon: <CIcon icon={cilPenNib} customClassName="nav-icon" />,
            roles: ['Admin', 'superadmin'],
          },
          {
            component: CNavItem,
            name: 'Item/Resource',
            to: PROCUREMENTMASTERLIST,
            values: ['ItemResource'],
            icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
            roles: ['Admin', 'superadmin'],
          },
          {
            component: CNavItem,
            name: 'Supplier',
            to: SUPPLIERTABLE,
            values: ['Supplier'],
            icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'User',
            to: USERMASTERLIST,
            values: ['user'],
            icon: <CIcon icon={cilGolf} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Resource Category',
            to: RESOURCECATEGORYLIST,
            values: ['ResourceCategory'],
            icon: <CIcon icon={cilGolf} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Salesperson',
            to: SALESPERSONSTABLE,
            values: ['Salesperson'],
            icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Payment Plan',
            to: PAYMENTPLAN,
            values: ['PaymentPlan'],
            icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Developer',
            to: DEVELOPERLIST,
            values: ['Developer'],
            icon: <CIcon icon={cilHouse} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'HRMS',
            to: EMPLOYEELIST,
            values: ['employee'],
            icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
          },

          {
            component: CNavGroup,
            name: 'Asset',
            to: '/assets',
            values: ['AssetMaster', 'SubAssetMaster', 'AssetCategory'],
            icon: <CIcon icon={cilHouse} customClassName="nav-icon" />,
            roles: ['Admin', 'superadmin', 'facilitymanager'],
            items: [
              {
                component: CNavItem,
                name: 'Asset Master',
                to: ASSETMASTER,
                values: ['AssetMaster'],
                icon: <CIcon icon={cilCouch} customClassName="nav-icon" />,
              },
              {
                component: CNavItem,
                name: 'Sub Asset Master',
                to: SUBASSETMASTER,
                values: ['SubAssetMaster'],
                icon: <CIcon icon={cilBed} customClassName="nav-icon" />,
              },
              {
                component: CNavItem,
                name: 'Asset Category',
                to: ASSETCATEGORYLIST,
                values: ['AssetCategory'],
                icon: <CIcon icon={cilLan} customClassName="nav-icon" />,
              },
            ],
          },

          {
            component: CNavGroup,
            name: 'VAT',
            // to: '/assets',
            values: ['VatRegion', 'VatMaster'],
            icon: (
              <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />
            ),
            roles: ['Admin', 'superadmin', 'facilitymanager'],
            items: [
              {
                component: CNavItem,
                name: 'VAT Region',
                to: VATREGION,
                values: ['VatRegion'],
                icon: <CIcon icon={cilGlobeAlt} customClassName="nav-icon" />,
              },
              {
                component: CNavItem,
                name: 'VAT Master',
                to: VATMASTER,
                values: ['VatMaster'],
                icon: <CIcon icon={cilStorage} customClassName="nav-icon" />,
              },
            ],
          },
        ],
      },
      {
        component: CNavGroup,
        name: 'Sub Masters',
        to: MASTER,
        values: [
          'submasters',
          'complaintSubMaster',
          'contactsSubMaster',
          'propertySubMaster',
          'communitySubMaster',
          'unitSubMaster',
          'technicianSubMaster',
          'supervisorSubMaster',
          'customerCareSubMaster',
          'leadsSubMaster',
          'sparePartsSubMaster',
          'itemResourceSubMaster',
          'supplierSubMaster',
          'assetSubMaster',
          'leaseSubMaster',
          'salesSubMaster',
          'employeeSubMaster',
          'facilitySubMaster',
          'energyManagementSubMaster',
          'checklistAssetitemSubMaster',
          'checklistItemSubMaster',
          'questionnaireInspectionSubMaster',
        ],
        icon: <CIcon icon={cilBrowser} customClassName="nav-icon" />,
        roles: ['Admin', 'superadmin'],
        items: [
          {
            component: CNavItem,
            name: 'Work Order',
            to: MASTER,
            values: ['complaintSubMaster'],
            icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Contact',
            to: CONTACTMASTER,
            values: ['contactsSubMaster'],
            icon: <CIcon icon={cilContact} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Property',
            to: PROPERTYMASTER,
            values: ['propertySubMaster'],
            icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Community',
            to: COMMUNITYMASTER,
            values: ['communitySubMaster'],
            icon: <CIcon icon={cilObjectGroup} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Unit',
            to: UNITSUBMASTER,
            values: ['unitSubMaster'],
            icon: <CIcon icon={cilGolf} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Technicians',
            to: TECHNICIANLIST,
            values: ['technicianSubMaster'],
            icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Supervisors',
            to: SUPERVISORSLIST,
            values: ['supervisorSubMaster'],
            icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Customer Care ',
            to: CUSTOMERCARE,
            values: ['customerCareSubMaster'],
            icon: <CIcon icon={cilHeadphones} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Leads',
            to: LEADSMASTERSUB,
            values: ['leadsSubMaster'],
            icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
          },
          // {
          //   component: CNavItem,
          //   name: 'Spare Parts',
          //   to: SPAREPARTSMASTERSUB,
          //   values: ['sparePartsSubMaster'],
          //   icon: <CIcon icon={cilPenNib} customClassName="nav-icon" />,
          // },
          {
            component: CNavItem,
            name: 'Item/Resource',
            to: PROCUREMENTMASTERSUB,
            values: ['itemResourceSubMaster'],
            icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Supplier',
            to: SUPPLIERSUBMASTER,
            values: ['supplierSubMaster'],
            icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'HRMS',
            to: EMPLOYEESUBMASTER,
            values: ['employeeSubMaster'],
            icon: <CIcon icon={cilHeadphones} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Asset',
            to: ASSETSUBMASTER,
            values: ['assetSubMaster'],
            icon: <CIcon icon={cilCouch} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Lease',
            to: LEASESUBMASTER,
            values: ['leaseSubMaster'],
            icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Sales',
            to: SALESSUBMASTER,
            values: ['salesSubMaster'],
            icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'User',
            to: USERSUBMASTER,
            values: ['userSubMaster'],
            icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Facility Management',
            to: JOBPLANERSUBMASTER,
            values: ['facilitySubMaster'],
            icon: <CIcon icon={cilObjectGroup} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Energy Management',
            to: ENERGYMANAGEMENTSUBMASTER,
            values: ['energyManagementSubMaster'],
            icon: <CIcon icon={cilBrightness} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Checklist Asset Item',
            to: CHECKLISTASSETITEM,
            values: ['checklistAssetitemSubMaster'],
            icon: <CIcon icon={cilFridge} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Checklist Item',
            to: CHECKLISTITEM,
            values: ['checklistItemSubMaster'],
            icon: <CIcon icon={cilTask} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Inspection Questionnaire',
            to: QUESTIONNAIREINSPECTION,
            values: ['questionnaireInspectionSubMaster'],
            icon: <CIcon icon={cilStorage} customClassName="nav-icon" />,
          },
          // {
          //   component: CNavItem,
          //   name: 'Utility Billing',
          //   to: UTILITYSUBMASTER,
          //   values: ['userSubMaster'],
          //   icon: <CIcon icon={cilStorage} customClassName="nav-icon" />,
          // },
        ],
      },
      // {
      //   component: CNavItem,
      //   name: 'Roles',
      //   to: ROLELIST,
      //   values: ['roles'],
      //   icon: (
      //     <UserPeopleIcon
      //       className="nav-icon"
      //       style={{ marginRight: '10px' }}
      //     />
      //   ),
      //   roles: ['Admin', 'superadmin'],
      // },
    ],
  },
  {
    component: CNavGroup,
    name: 'CRM ',
    to: '/crmmodule',
    // values: ["crm"],
    values: ['CRM', 'contacts', 'Leads'],
    icon: <Crm className="nav-icon" />,
    roles: ['Admin', 'superadmin', 'facilitymanager'],
    items: [
      {
        component: CNavItem,
        name: 'Dashboard',
        to: CRMDASHBOARD,
        values: ['crmdashboard'],
        icon: (
          <Dashboard className="nav-icon" style={{ marginRight: '10px' }} />
        ),
      },
      {
        component: CNavItem,
        name: 'Contacts',
        to: '/contacts',
        values: ['contacts'],
        icon: (
          <UserPeopleIcon
            className="nav-icon"
            style={{ marginRight: '10px' }}
          />
        ),
      },
      {
        component: CNavItem,
        name: 'Leads',
        to: '/leads',
        values: ['Leads'],
        icon: <Crm className="nav-icon" />,
        // roles: ['Admin', 'superadmin', 'facilitymanager'],
      },
      {
        component: CNavGroup,
        name: 'Reports',
        to: CONTACTSREPORT,
        values: [
          'contactreports',
          'contactactivityreports',
          'contactpreferencesreports',
          'propertytourreports',
          'leadsreports',
        ],
        icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
        items: [
          {
            component: CNavItem,
            name: 'Contacts Report',
            to: CONTACTSREPORT,
            values: ['contactreports'],
            icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Contact Activity Report',
            to: CONTACTACTIVITYREPORT,
            values: ['contactactivityreports'],
            icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Contact Preference Report',
            to: CONTACTPREFERENCEREPORT,
            values: ['contactpreferencesreports'],
            icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Property Tour Report',
            to: PROPERTYTOURREPORT,
            values: ['propertytourreports'],
            icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Email Campaign Report',
            to: EMAILCAMPAIGNREPORT,
            values: ['propertytourreports'],
            icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
          },
          {
            component: CNavItem,
            name: 'Leads Report',
            to: LEADSLISTINGREPORT,
            values: ['leadsreports'],
            icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
          },
        ],
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Lease',
    to: LEASE,
    values: ['Lease', 'Leaseoffer', 'Leaseagreement'],
    icon: <CIcon icon={cilBriefcase} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Lease Offer',
        to: LEASE_OFFER,
        values: ['Leaseoffer'],
        icon: (
          <Dashboard className="nav-icon" style={{ marginRight: '10px' }} />
        ),
      },
      {
        component: CNavItem,
        name: 'Lease Agreement',
        to: LEASE,
        values: ['Leaseagreement'],
        icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Sales',
    to: SALESOFFERLISTING,
    values: [
      'salesdashboard',
      'salesOffer',
      'ContractAgreement',
      'DealCancellation',
      'salesDetailsReport',
    ],
    icon: <Sales className="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Dashboard',
        to: SALESDASHBOARD,
        values: ['salesdashboard'],
        icon: (
          <Dashboard className="nav-icon" style={{ marginRight: '10px' }} />
        ),
      },
      {
        component: CNavItem,
        name: 'Sales Offer',
        to: SALESOFFERLISTING,
        values: ['salesOffer'],
        icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Contract Agreement',
        to: SALESCONTRACTAGREEMENTLIST,
        values: ['ContractAgreement'],
        icon: <CIcon icon={cilPenNib} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Deal Cancellation',
        to: DEALCANCELLATIONLIST,
        values: ['DealCancellation'],
        icon: <CIcon icon={cilFactorySlash} customClassName="nav-icon" />,
      },
      {
        component: CNavGroup,
        name: 'Reports',
        to: SALESREPORT,
        values: ['salesDetailsReport'],
        icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
        roles: ['Admin', 'superadmin', 'facilitymanager'],
        items: [
          {
            component: CNavItem,
            name: 'Sales Details Report',
            to: SALESREPORT,
            values: ['salesDetailsReport'],
            icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
          },
        ],
      },
    ],
  },

  {
    component: CNavGroup,
    name: 'Approval',
    values: ['approvalworkflow', 'approvalrequests'],
    icon: <AddTaskOutlinedIcon style={{ marginRight: '10px' }} />,
    items: [
      {
        component: CNavItem,
        name: 'Approval Requests',
        to: APPROVALREQUEST,
        values: ['approvalrequests'],
        icon: <ThumbUpAltOutlinedIcon style={{ marginRight: '10px' }} />,
      },
      {
        component: CNavItem,
        name: 'Approval WorkFlow',
        to: APPROVALWORKFLOWLIST,
        values: ['approvalworkflow'],
        icon: <AccountTreeOutlinedIcon style={{ marginRight: '10px' }} />,
      },
    ],
  },

  // {
  //   component: CNavGroup,
  //   name: 'Utility Billing',
  //   values: ['approvalworkflow', 'approvalrequests'],
  //   icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Meter Master',
  //       to: METERMASTER,
  //       values: ['approvalrequests'],
  //       icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Meter Readings',
  //       to: METERREADINGLISTING,
  //       values: ['approvalworkflow'],
  //       icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
  //     },
  //   ],
  // },

  {
    component: CNavItem,
    name: 'Audit Trail',
    to: AUDITTRAIL,
    values: ['audittrail'],
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Invoice',
  //   to: INVOICELIST,
  //   values: ['contacts'],
  //   icon: (
  //     <RequestQuoteOutlinedIcon
  //       style={{ marginRight: '10px', fontSize: 'xx-large' }}
  //     />
  //   ),
  //   roles: ['Admin', 'superadmin'],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Receipt',
  //   to: RECEIPTLIST,
  //   values: ['contacts'],
  //   icon: (
  //     <ReceiptLongOutlinedIcon
  //       style={{ marginRight: '10px', fontSize: 'xx-large' }}
  //     />
  //   ),
  //   roles: ['Admin', 'superadmin'],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Payment',
  //   to: PAYMENTLIST,
  //   values: ['contacts'],
  //   icon: (
  //     <PaymentsOutlinedIcon
  //       style={{ marginRight: '10px', fontSize: 'xx-large' }}
  //     />
  //   ),
  //   roles: ['Admin', 'superadmin'],
  // },
];

export default _nav;
